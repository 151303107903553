import React, { createContext, memo, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useApi } from '../../../hooks/useApi';
import { useAsync } from '../../../hooks/useAsync';
import getQuoteWithCoverages from './getQuoteWithCoverages';
import { getTotalAllInCostValue } from '../../../utils/getTotalAllInCostValue';

const QuoteDataContext = createContext({ data: null, updating: true, update: () => null });

function useQuoteData() {
  return useContext(QuoteDataContext);
}

const ProvideQuoteData = memo(function ProvideQuoteData(props) {
  const { id, renderLoading, renderEmpty, children } = props;

  const { getQuoteWithId, getApplicationWithId, getCoveragesForProductId, getMacavityForQuote, getQuoteConcentrations } = useApi();

  const [macavity, setMacavity] = useState(null);
  const [maxCoverageLimits, setMaxCoverageLimit] = useState([]);
  const [concentrations, setConcentrations] = useState(null);

  const getQuoteDataAndCoverages = useCallback(async () => {
    const quote = await getQuoteWithId(id);
    const application = await getApplicationWithId(quote.applicationId);
    const productCoverages = await getCoveragesForProductId(quote.product.id);

    const coverageLimits = productCoverages.map(({ id, coverageType }) => {
      const updatedCoverageType = coverageType.charAt(0).toUpperCase() + coverageType.slice(1);
      return {
        id,
        coverageType,
        valueName: updatedCoverageType.match(/[A-Z][a-z]+/g).join(' '),
        maxLimit: 0,
      };
    });

    let macavityData;
    try {
      macavityData = await getMacavityForQuote(quote.id);
      setMacavity(macavityData);
    } catch (error) {
      macavityData = null;
    }

    if (!quote) return null;
    return {
      data: {
        ...getQuoteWithCoverages(quote),
        application,
      },
      productCoverages,
      macavity: macavityData,
      coverageLimits,
    };
  }, [id, getQuoteWithId, getApplicationWithId, getCoveragesForProductId, getMacavityForQuote, getQuoteConcentrations]);

  const { value, status, execute, lastUpdated } = useAsync(getQuoteDataAndCoverages);

  const updateMacavity = useCallback(async () => {
    try {
      const updatedMacavity = await getMacavityForQuote(id);
      setMacavity(updatedMacavity); // Update macavity in state
    } catch (error) {
      console.error('Error updating macavity:', error);
    }
  }, [id, getMacavityForQuote]);

  const { data = null, productCoverages, coverageLimits } = value || {};
  useEffect(() => {
    setMaxCoverageLimit(coverageLimits);
  }, [coverageLimits]);

  const context = useMemo(
    () => ({
      data,
      totalAllInCost: getTotalAllInCostValue(data),
      updating: data && status === 'pending',
      update: execute,
      updateMacavity,
      productCoverages,
      macavity,
      lastUpdated,
      maxCoverageLimits,
      setMaxCoverageLimit,
      concentrations,
      setConcentrations,
    }),
    [data, status, execute, lastUpdated, macavity, productCoverages, updateMacavity, maxCoverageLimits, concentrations, setConcentrations]
  );

  if (!data && status === 'pending') {
    return renderLoading ? renderLoading() : children;
  }

  if (!data) {
    return renderEmpty ? renderEmpty() : children;
  }

  return <QuoteDataContext.Provider value={context}>{children}</QuoteDataContext.Provider>;
});

export { QuoteDataContext, useQuoteData, ProvideQuoteData };
