import React, { useEffect, useState } from 'react';
import { Box } from '../../../../ui';
import { ViewTabs } from '../../../../components/ViewTabs';
import { Coverages } from '../QuoteGrids/Coverages';
import { RiskConcentration } from '../QuoteGrids/RiskConcentration';
import { Endorsements } from '../QuoteGrids/Endorsements';
import { CATModeling } from '../QuoteGrids/CATModeling';
import { BindFormsView } from '../BindFormsView';
import { Fees } from '../QuoteGrids/Fees';
import { MiscValues } from '../QuoteGrids/MiscValues';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { exportAllGrids } from '../../../../utils/exportAllGrids';
import { useApi } from '../../../../hooks';
import { useQuoteData } from '../../context/QuoteDataContext';

export const QuotesViewTabs = {
  FEES: 'FEES',
  COVERAGES: 'COVERAGES',
  CAT_MODELING: 'CAT_MODELING',
  RISK_CONCENTRATION: 'RISK_CONCENTRATION',
  BIND_FORMS: 'BIND_FORMS',
  ENDORSEMENTS: 'ENDORSEMENTS',
  MISC_VALUES: 'MISC_VALUES',
};
const QuotesGridView = ({
  tab,
  data,
  productCoverages,
  macavity,
  setGridReference,
  setEndorsmentGridReference,
  onSelectionChanged,
  update,
  updating,
  lastUpdated,
  modalOpen,
  setModalOpen,
  quoteData,
  setQuoteData,
  gridReferences,
  importModal,
  setImportModal,
  handleAddLocation,
  allowDeleteCoverage = false,
  shouldShowWarning,
  setShouldShowWarning,
  setActiveExportFunction,
  setGridReferenceForTab,
  applicationTabsKeys,
}) => {
  return (
    <div>
      {applicationTabsKeys.includes(QuotesViewTabs.FEES) && (
        <div style={{ display: tab === QuotesViewTabs.FEES ? 'block' : 'none' }}>
          <Fees
            quotes={quoteData}
            onSelectionChanged={onSelectionChanged}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            setQuoteData={setQuoteData}
            gridReference={gridReferences?.FEES}
            importModal={importModal}
            setImportModal={setImportModal}
            setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.FEES)}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'FEES', ref })}
          />
        </div>
      )}

      {applicationTabsKeys.includes(QuotesViewTabs.COVERAGES) && (
        <div style={{ display: tab === QuotesViewTabs.COVERAGES ? 'block' : 'none' }}>
          <Coverages
            quotes={quoteData}
            productCoverages={productCoverages}
            onSelectionChanged={onSelectionChanged}
            modalOpen={modalOpen}
            setQuoteData={setQuoteData}
            setModalOpen={setModalOpen}
            importModal={importModal}
            gridReference={gridReferences?.COVERAGES}
            setImportModal={setImportModal}
            setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.COVERAGES)}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'COVERAGES', ref })}
            handleAddLocation={handleAddLocation}
            allowDeleteCoverage={allowDeleteCoverage}
            shouldShowWarning={shouldShowWarning}
            setShouldShowWarning={setShouldShowWarning}
          />
        </div>
      )}

      {applicationTabsKeys.includes(QuotesViewTabs.CAT_MODELING) && (
        <div style={{ display: tab === QuotesViewTabs.CAT_MODELING ? 'block' : 'none' }}>
          <CATModeling
            macavity={macavity}
            quote={data}
            gridReference={gridReferences?.CAT_MODELING}
            setExportHandler={(exportFn, tableName) => setActiveExportFunction(exportFn, QuotesViewTabs.CAT_MODELING, tableName)} // Set export handler for specific tables
            setGridReferenceForTab={setGridReferenceForTab}
          />
        </div>
      )}

      {applicationTabsKeys.includes(QuotesViewTabs.RISK_CONCENTRATION) && (
        <div style={{ display: tab === QuotesViewTabs.RISK_CONCENTRATION ? 'block' : 'none' }}>
          <RiskConcentration
            quotes={data}
            gridReference={gridReferences?.RISK_CONCENTRATION}
            setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.RISK_CONCENTRATION)}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'RISK_CONCENTRATION', ref })}
          />
        </div>
      )}

      {applicationTabsKeys.includes(QuotesViewTabs.BIND_FORMS) && (
        <div style={{ display: tab === QuotesViewTabs.BIND_FORMS ? 'block' : 'none' }}>
          <BindFormsView data={data} update={update} updating={updating} lastUpdated={lastUpdated} />
        </div>
      )}

      {applicationTabsKeys.includes(QuotesViewTabs.ENDORSEMENTS) && (
        <div style={{ display: tab === QuotesViewTabs.ENDORSEMENTS ? 'block' : 'none' }}>
          <Endorsements setGridReference={setGridReference} setEndorsmentGridReference={setEndorsmentGridReference} quotes={quoteData} />
        </div>
      )}
      {applicationTabsKeys.includes(QuotesViewTabs.MISC_VALUES) && (
        <div style={{ display: tab === QuotesViewTabs.MISC_VALUES ? 'block' : 'none' }}>
          <MiscValues setGridReference={setGridReference} />
        </div>
      )}
    </div>
  );
};

export const QuoteTabs = React.forwardRef((props, ref) => {
  const { getQuoteConcentrations } = useApi();
  const { setConcentrations } = useQuoteData();
  const [modalOpen, setModalOpen] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [gridParamsEndorsement, setGridParamsEndorsement] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [exportHandlers, setExportHandlers] = useState({});
  const { isAdjustmentSuccessFul, adjustmentResponse, setAdjustmentResponse, visitedTabs, setVisitedTabs } = useAdjustmentState();
  const quoteTabsKeys = Object.keys(props?.applicationTabsKeys || QuotesViewTabs);
  const [quoteData, setQuoteData] = useState(props.data);
  const [importModal, setImportModal] = useState(false);
  const [gridReferences, setGridReferences] = useState({
    FEES: null,
    COVERAGES: null,
    CAT_MODELING: {
      table0: null,
      table1: null,
    },
    RISK_CONCENTRATION: null,
  });
  const [exportInProgress, setExportInProgress] = useState(false);

  const setGridReferenceForTab = ({ tabName, tableName, ref }) => {
    if (tabName === 'CAT_MODELING') {
      setGridReferences((prevRefs) => ({
        ...prevRefs,
        CAT_MODELING: {
          ...prevRefs.CAT_MODELING,
          [tableName]: ref,
        },
      }));
    } else {
      setGridReferences((prevRefs) => ({
        ...prevRefs,
        [tabName]: ref,
      }));
    }
  };

  const setActiveExportFunction = (exportFn, gridType, tableName = null) => {
    setExportHandlers((prevHandlers) => {
      if (gridType === 'CAT_MODELING') {
        return {
          ...prevHandlers,
          CAT_MODELING: {
            ...(prevHandlers.CAT_MODELING || {}),
            [tableName]: exportFn,
          },
        };
      } else {
        return {
          ...prevHandlers,
          [gridType]: exportFn,
        };
      }
    });
  };

  const setGridReference = (params) => {
    setGridParams(params);
  };

  const setEndorsmentGridReference = (params) => {
    setGridParamsEndorsement(params);
  };

  const onSelectionChanged = (context) => {
    if (context) {
      const fields = context.api.getSelectedRows();
      setSelectedFields(fields);
    }
  };

  const applicationTabsKeys = Object.keys(props.applicationTabsKeys);
  const initialTab = applicationTabsKeys[0];
  const [viewTab, setViewTab] = useState(initialTab || QuotesViewTabs.FEES);
  useEffect(() => {
    setModalOpen(false);
  }, [viewTab]);

  const getLabel = (name, label) => {
    if (name === QuotesViewTabs.FEES || name === QuotesViewTabs.MCE_FEES) {
      return 'Fees';
    }
    if (name === QuotesViewTabs.COVERAGES) {
      return `Coverages`;
    }
    if (name === QuotesViewTabs.CAT_MODELING) {
      return `CAT Modeling`;
    }
    if (name === QuotesViewTabs.RISK_CONCENTRATION) {
      return `Risk Concentration`;
    }
    if (name === QuotesViewTabs.BIND_FORMS) return `Bind Forms`;
    if (name === QuotesViewTabs.ENDORSEMENTS) return `Endorsements`;
    if (name === QuotesViewTabs.MISC_VALUES) return 'Misc Values';
    return label;
  };

  const fetchRiskConcentrationsData = async () => {
    try {
      const data = await getQuoteConcentrations(quoteData.id);
      setConcentrations(data);
      return data;
    } catch (error) {
      console.error('Error fetching risk concentrations data:', error);
      return null;
    }
  };

  const handleExport = async () => {
    setExportInProgress(true);

    try {
      const tabsToFetch = Object.keys(visitedTabs).filter((tab) => !visitedTabs[tab]);

      if (tabsToFetch.length > 0) {
        await Promise.all(
          tabsToFetch.map(async (tab) => {
            try {
              const data = await fetchTabData(tab);
              if (data) {
                setVisitedTabs((prev) => ({
                  ...prev,
                  [tab]: true,
                }));
              }
            } catch (error) {
              console.error(`Error fetching data for tab ${tab}:`, error);
            }
          })
        );
      }
      exportAllGrids({ gridReferences, exportHandlers, workbookName: quoteData.insured });
    } catch (error) {
      console.error('Export failed:', error);
    } finally {
      setExportInProgress(false);
    }
  };

  const fetchTabData = async (tab) => {
    switch (tab) {
      case 'riskConcentrations':
        return await fetchRiskConcentrationsData(quoteData?.id);
      default:
        return null;
    }
  };

  useEffect(() => {
    setQuoteData(props.data);
  }, [props.data]);

  return (
    <>
      <Box
        backgroundColor={'white'}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="#DFE3E8"
        padding={'0px 18px'}
        justifyContent="space-between"
        display="flex"
        paddingLeft={10}
      >
        <Box display="flex" flex={1} flexWrap="wrap" columnGap="24px" rowGap="8px">
          <ViewTabs
            getLabel={getLabel}
            tabs={quoteTabsKeys}
            onTabChanged={setViewTab}
            gridReference={gridParams}
            endorsementGridReference={gridParamsEndorsement}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            quoteData={quoteData}
            setQuoteData={setQuoteData}
            importModal={importModal}
            setImportModal={setImportModal}
            quotesView={true}
            onExport={handleExport}
            isAdjustmentSuccessFul={isAdjustmentSuccessFul}
            adjustmentResponse={adjustmentResponse}
            setAdjustmentResponse={setAdjustmentResponse}
            exportInProgress={exportInProgress}
            {...props}
          />
        </Box>
      </Box>
      <QuotesGridView
        tab={viewTab}
        {...props}
        setGridReference={setGridReference}
        setEndorsmentGridReference={setEndorsmentGridReference}
        onSelectionChanged={onSelectionChanged}
        modalOpen={modalOpen}
        quoteData={quoteData}
        gridReferences={gridReferences}
        setQuoteData={setQuoteData}
        setModalOpen={setModalOpen}
        importModal={importModal}
        setImportModal={setImportModal}
        setActiveExportFunction={setActiveExportFunction}
        setGridReferenceForTab={setGridReferenceForTab}
        applicationTabsKeys={applicationTabsKeys}
      />
    </>
  );
});
