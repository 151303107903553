import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '../../../../ui';
import { ViewTabs } from '../../../../components/ViewTabs';
import { Coverages } from '../../../QuotePage/content/QuoteGrids/Coverages';
import { MiscPolicyDetails } from '../../MiscPolicyDetails';
import { MiscValues } from '../../../QuotePage/content/QuoteGrids/MiscValues';
import { MonthlyReporting } from '../MonthlyReporting';
import { PremiumCalculation } from '../../../MCEPage/content/PremiumCalculation';
import { MCEFees } from '../../../MCEPage/content/MCEFees';
import { exportAllGrids } from '../../../../utils/exportAllGrids';
import { isEmpty } from '../../../../ui/utils';
import { Endorsements } from '../../Endorsements';

export const policyViewTabs = {
  COVERAGES: 'COVERAGES',
  MISC_VALUES: 'MISC_VALUES',
  MCES: 'MCES',
  MONTHLY_REPORTING: 'MONTHLY_REPORTING',
  PREMIUM_CALCULATION: 'PREMIUM_CALCULATION',
  MCE_FEES: 'MCE_FEES',
  ENDORSEMENTS: 'ENDORSEMENTS',
};
const PolicyGridView = ({
  tab,
  data,
  productCoverages,
  setGridReference,
  onSelectionChanged,
  modalOpen,
  setModalOpen,
  quoteData,
  setQuoteData,
  gridReferences,
  importModal,
  setImportModal,
  handleAddLocation,
  allowDeleteCoverage = false,
  shouldShowWarning,
  setShouldShowWarning,
  setActiveExportFunction,
  setGridReferenceForTab,
  policyTabsList,
  monthlyReportingData,
  MCEsData,
}) => {
  return (
    <div>
      {policyTabsList.includes(policyViewTabs.COVERAGES) && (
        <div style={{ display: tab === policyViewTabs.COVERAGES ? 'block' : 'none' }}>
          <Coverages
            quotes={quoteData}
            productCoverages={productCoverages}
            onSelectionChanged={onSelectionChanged}
            modalOpen={modalOpen}
            setQuoteData={setQuoteData}
            setModalOpen={setModalOpen}
            importModal={importModal}
            gridReference={gridReferences?.COVERAGES}
            setImportModal={setImportModal}
            setExportHandler={(exportFn) => setActiveExportFunction(exportFn, policyViewTabs.COVERAGES)}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'COVERAGES', ref })}
            handleAddLocation={handleAddLocation}
            allowDeleteCoverage={allowDeleteCoverage}
            shouldShowWarning={shouldShowWarning}
            setShouldShowWarning={setShouldShowWarning}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MCES) && (
        <div style={{ display: tab === policyViewTabs.MCES ? 'block' : 'none' }}>
          <MiscPolicyDetails setGridReference={setGridReference} policy={data} MCEsData={MCEsData} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MISC_VALUES) && (
        <div style={{ display: tab === policyViewTabs.MISC_VALUES ? 'block' : 'none' }}>
          <MiscValues setGridReference={setGridReference} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MONTHLY_REPORTING) && (
        <div style={{ display: tab === policyViewTabs.MONTHLY_REPORTING ? 'block' : 'none' }}>
          <MonthlyReporting setGridReference={setGridReference} policy={data} monthlyReportingData={monthlyReportingData} />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.PREMIUM_CALCULATION) && (
        <div style={{ display: tab === policyViewTabs.PREMIUM_CALCULATION ? 'block' : 'none' }}>
          <PremiumCalculation
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'PREMIUM_CALCULATION', ref })}
            policy={data}
            monthlyReportingData={monthlyReportingData}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.MCE_FEES) && (
        <div style={{ display: tab === policyViewTabs.MCE_FEES ? 'block' : 'none' }}>
          <MCEFees
            policy={data}
            gridReference={gridReferences?.MCE_FEES}
            setGridReference={(ref) => setGridReferenceForTab({ tabName: 'MCE_FEES', ref })}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      )}
      {policyTabsList.includes(policyViewTabs.ENDORSEMENTS) && (
        <div style={{ display: tab === policyViewTabs.ENDORSEMENTS ? 'block' : 'none' }}>
          <Endorsements setGridReference={(ref) => setGridReferenceForTab({ tabName: 'ENDORSEMENTS', ref })} policy={data} />
        </div>
      )}
    </div>
  );
};

export const PolicyTabs = React.forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [exportHandlers, setExportHandlers] = useState({});
  const [quoteData, setQuoteData] = useState(props.data);
  const { setMCEDetails, MCEDetails, setDeletedLocations, setPremiumData } = props;

  const policyTabsKeys = Object.keys(props?.policyTabsList || policyViewTabs);
  const [gridReferences, setGridReferences] = useState({
    COVERAGES: null,
    MCE_FEES: null,
    MONTHLY_REPORTING: null,
    MCES: null,
    ENDORSEMENTS: null,
  });

  const setGridReferenceForTab = ({ tabName, tableName, ref }) => {
    setGridReferences((prevRefs) => ({
      ...prevRefs,
      [tabName]: ref,
    }));
  };

  const handleDeleteMCELocation = async () => {
    try {
      const { premiumData } = props;
      const selectedFields = gridReferences['PREMIUM_CALCULATION'].api.getSelectedRows();

      if (!selectedFields || selectedFields.length === 0) {
        alert('Please select locations to delete.');
        return;
      }

      if (!premiumData || premiumData.length === 0) {
        console.warn('No premiumData available to update.');
        return;
      }

      setDeletedLocations(selectedFields);

      const updatedPremiumData = premiumData.map((row) => {
        const isSelected = selectedFields.some((selectedRow) => selectedRow.locationId === row.locationId);
        return isSelected ? { ...row, isRowDeleted: true, avg_declared_inventory: 0 } : row;
      });
      setPremiumData(updatedPremiumData);
    } catch (error) {
      console.error('Failed to update premium data:', error);
      alert('An error occurred while updating the premium data. Please try again.');
    }
  };

  const handleDeleteFee = async (e) => {
    e.preventDefault();
    const fields = gridReferences['MCE_FEES'].api.getSelectedRows();
    if (isEmpty(fields) || !fields) return;
    const result = MCEDetails?.fees.filter((item) => !fields.find((selectedItem) => selectedItem.id === item.id));
    setMCEDetails((pre) => {
      return { ...pre, fees: result };
    });
  };

  const setActiveExportFunction = (exportFn, gridType) => {
    setExportHandlers((prevHandlers) => {
      return {
        ...prevHandlers,
        [gridType]: exportFn,
      };
    });
  };

  const setGridReference = (params) => {
    setGridParams(params);
  };

  const onSelectionChanged = useCallback((context) => {
    if (context) {
      const fields = context.api.getSelectedRows();
    }
  }, []);

  useEffect(() => {
    setQuoteData(props.data);
  }, [props.data]);

  const policyTabsList = Object.keys(props.policyTabsList);
  const initialTab = policyTabsList[0];
  const [viewTab, setViewTab] = useState(initialTab || policyViewTabs.FEES);
  useEffect(() => {
    setModalOpen(false);
  }, [viewTab]);

  const getLabel = (name, label) => {
    if (name === policyViewTabs.MCE_FEES) {
      return 'Fees';
    }
    if (name === policyViewTabs.COVERAGES) {
      return `Coverages`;
    }
    if (name === policyViewTabs.MCES) return 'Misc. Change Endorsements(MCEs)';
    if (name === policyViewTabs.MONTHLY_REPORTING) return 'Monthly Reporting';
    if (name === policyViewTabs.PREMIUM_CALCULATION) return 'Premium Calculation';
    if (name === policyViewTabs.ENDORSEMENTS) return 'Endorsements';

    return label;
  };

  return (
    <>
      <Box
        backgroundColor={'white'}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="#DFE3E8"
        padding={'0px 18px'}
        justifyContent="space-between"
        display="flex"
        paddingLeft={10}
      >
        <Box display="flex" flex={1} flexWrap="wrap" columnGap="24px" rowGap="8px">
          <ViewTabs
            getLabel={getLabel}
            tabs={policyTabsKeys}
            onTabChanged={setViewTab}
            gridReference={gridParams}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            setQuoteData={setQuoteData}
            quotesView={false}
            quoteData={quoteData}
            handleDeleteFee={handleDeleteFee}
            handleDeleteMCELocation={handleDeleteMCELocation}
            onExport={() => exportAllGrids({ gridReferences, exportHandlers, workbookName: 'policy' })}
            {...props}
          />
        </Box>
      </Box>
      <PolicyGridView
        tab={viewTab}
        {...props}
        setGridReference={setGridReference}
        onSelectionChanged={onSelectionChanged}
        modalOpen={modalOpen}
        gridReferences={gridReferences}
        setModalOpen={setModalOpen}
        setQuoteData={setQuoteData}
        setActiveExportFunction={setActiveExportFunction}
        setGridReferenceForTab={setGridReferenceForTab}
        policyTabsList={policyTabsList}
        quoteData={quoteData}
      />
    </>
  );
});
