import React, { useEffect, useState } from 'react';
import { NoEndorsmentIcon, TrashBinIconLarge } from '../../components/icons';
import { Box, Button, Checkbox, GridModal, Text, TextInput } from '../../ui';
import { LayoutBox } from '../../components';
import { useApi } from '../../hooks';

const UpdateNamedEndorsementModal = ({ policy, showModal, setShowModal }) => {
  const { updateNamedInsured } = useApi();
  const [namedEndorsements, setNamedEndorsements] = useState(policy.namedInsureds);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return setNamedEndorsements(policy.namedInsureds);
  }, []);

  const handleUpdateNamedInsured = async () => {
    setIsLoading(true);
    try {
      await updateNamedInsured(policy.id, { ...policy, namedInsureds: namedEndorsements });
      alert('Named Insured Updated Successfully.');
    } catch (error) {
      console.error('Error updating named insureds:', error);
      alert('An error occurred while updating named insureds. Please try again.');
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const handleAddInsuredName = (event, index) => {
    const enteredName = event.target.value;
    setNamedEndorsements((prevEndorsements) => prevEndorsements.map((name, idx) => (idx === index ? enteredName : name)));
  };

  const handleDeleteNamedEndorsement = (index) => {
    setNamedEndorsements((prevEndorsements) => prevEndorsements.filter((_, idx) => idx !== index));
  };

  return (
    <GridModal
      open={showModal}
      title="Manage Named Insured Endorsement"
      onClose={() => setShowModal(false)}
      buttonText="Submit"
      buttonRadius="8px"
      handlePress={handleUpdateNamedInsured}
      capitalizeTitle
      disable={isLoading}
      loading={isLoading}
    >
      <LayoutBox
        style={{
          padding: '16px',
          height: '400px',
          overflowY: 'auto',
        }}
        gap={16}
      >
        {namedEndorsements.map((namedEndorsement, index) => (
          <LayoutBox
            row
            key={index}
            style={{
              border: '2px solid #919eab52',
              borderRadius: '8px',
            }}
            padX="$1.5"
            padY="$1"
          >
            <Box cursor="pointer" width="90%">
              <TextInput
                name="named-insurance"
                size="medium"
                color="#919EAB"
                width="95%"
                bold
                placeholder="Name of Insured"
                padY="$0"
                value={namedEndorsement}
                onChange={(e) => handleAddInsuredName(e, index)}
              />
            </Box>
            <Box cursor="pointer" onClick={() => handleDeleteNamedEndorsement(index)}>
              <TrashBinIconLarge />
            </Box>
          </LayoutBox>
        ))}
        <Button
          width="30%"
          label="Add Insured"
          borderRadius="8px"
          fontWeight="700"
          fontSize="14px"
          paddingBottom="6"
          paddingTop="6"
          paddingLeft="16"
          paddingRight="16"
          color="$gray-900"
          variant="text"
          border={{
            width: 2,
            color: '#919EAB52',
          }}
          onPress={() => setNamedEndorsements((prev) => [...prev, ''])}
        />
      </LayoutBox>
    </GridModal>
  );
};

export const Endorsements = React.forwardRef((props, ref) => {
  const { policy } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <LayoutBox flexDirection={'column'} layout="bottom-left" justifyContent="space-between" width="100%" backgroundColor="white">
      {!policy.namedInsureds.length && (
        <LayoutBox row style={{ padding: '16px' }} alignSelf="stretch" alignItems="center" gap={16} justifyContent="center" padX="$3">
          <LayoutBox
            row
            alignSelf="stretch"
            gap={32}
            alignItems="center"
            style={{ padding: '40px', borderRadius: '8px', border: '1px dashed rgba(145, 158, 171, 0.32)', backgroundColor: '#F4F6F8' }}
          >
            <Box style={{ padding: '44px' }}>
              <NoEndorsmentIcon />
            </Box>
            <LayoutBox style={{ display: 'flex', flexDirection: 'column' }} alignItems="flex-start">
              <Text style={{ fontWeight: '700', fontSize: '20px' }}>No Named Insureds</Text>
              <Text style={{ fontWeight: '700', fontSize: '14px' }} color="#637381">
                Add policy wide named insured endorsements here.
              </Text>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      )}
      <Box border="1px solid #919EAB52" borderRadius={8} margin="$2">
        <Checkbox checked={showModal} onChange={() => setShowModal(!showModal)} label="Named Insured Endorsement" color="$primary" />
      </Box>
      <UpdateNamedEndorsementModal showModal={showModal} setShowModal={setShowModal} policy={policy} />
    </LayoutBox>
  );
});
