import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import moment from 'moment-timezone';
import { Box, Text, Heading, Button, Spacing, Map, FileInput } from '../../ui';
import { getBounds } from '../../ui/utils';
import { formatMoney } from '../../utils';
import { useApi, useAsync, useNavigate } from '../../hooks';
import { TextData, NavBar, Page, NavBackButton, NavRow, NavGroup, LayoutBox } from '../../components';
import PageTitleAndAction from '../../components/PageTitleAndActions';
import { QuoteStat } from '../QuotePage/content/QuoteView/QuoteStat';
import DropdownMenu from '../../components/DropdownMenu';
import { PolicyPageContextProvider, usePolicyPageContext } from './context/PolicyPageContext';
import { calculateTotalGrossWrittenPremium, getTotalAllInCostValue } from '../../utils/getTotalAllInCostValue';
import { PolicyTabs } from './content/PolicyView/PolicyTabs';

const PolicyDetails = ({
  policy,
  currentTab,
  setEditMonthlyReporting,
  editMonthlyReporting,
  handleUpdateMonthlyReportingData,
  handleResetMonthlyReportingData,
  isUpdatingMonthly,
}) => {
  const navigate = useNavigate();
  const { regeneratePolicy } = useApi();

  const handleDownloadPolicy = useCallback(async () => {
    window.open(policy.downloadLink, '_blank');
  }, [policy.downloadLink]);
  const { execute: downloadPolicy } = useAsync(handleDownloadPolicy, { immediate: false });

  const handleRegenPolicy = useCallback(async () => {
    const response = await regeneratePolicy(policy.id);
    return response;
  }, [regeneratePolicy, policy.id]);

  const { execute: regenPolicy } = useAsync(handleRegenPolicy, { immediate: false });

  const ActionButtons = () => {
    return (
      <>
        {currentTab !== 'MONTHLY_REPORTING' ? (
          <LayoutBox row layout="center-right" gap={12}>
            <Button href={policy.downloadLink} target="_blank" color="$primary" rel="noreferrer" onPress={downloadPolicy} borderRadius={8}>
              Download Policy
            </Button>
            <DropdownMenu
              options={[
                { label: 'Submit Claim', onClick: () => navigate.to('/submitclaim') },
                { label: 'Regenerate Policy', onClick: () => regenPolicy() },
              ]}
            />
          </LayoutBox>
        ) : (
          <LayoutBox row layout="center-right" gap={12}>
            {!editMonthlyReporting ? (
              <Button
                target="_blank"
                color="$primary"
                rel="noreferrer"
                onPress={() => {
                  setEditMonthlyReporting(true);
                }}
                borderRadius={8}
              >
                Edit
              </Button>
            ) : (
              <LayoutBox row layout="center-right" gap={4}>
                <Button
                  target="_blank"
                  color="$primary"
                  rel="noreferrer"
                  onPress={() => {
                    setEditMonthlyReporting(false);
                    handleResetMonthlyReportingData();
                  }}
                  borderRadius={8}
                >
                  Cancel
                </Button>
                <Button
                  loading={isUpdatingMonthly}
                  disabled={isUpdatingMonthly}
                  target="_blank"
                  color="$primary"
                  rel="noreferrer"
                  onPress={() => {
                    handleUpdateMonthlyReportingData();
                  }}
                  borderRadius={8}
                >
                  Save
                </Button>
              </LayoutBox>
            )}
          </LayoutBox>
        )}
      </>
    );
  };

  const totalGrossPremium = calculateTotalGrossWrittenPremium(policy?.locations);

  const policyData = [
    {
      title: 'Gross Written Premium',
      text: `${formatMoney(totalGrossPremium, { prefix: '$', withDecimals: true })}`,
    },
    {
      title: 'Total All-In-Cost',
      text: `${formatMoney(getTotalAllInCostValue(policy), { prefix: '$', withDecimals: true })}`,
    },
    {
      title: 'Rate',
      text: policy.quoteRate
        ? `$ ${policy.quoteRate}`
        : !!policy?.coveredValue
        ? `$ ${((totalGrossPremium / policy.coveredValue) * 100).toFixed(4)}`
        : 'N/A',
    },
    {
      title: '∑ Avg Total Insured Value',
      text: `${formatMoney(policy.coveredValue, { prefix: '$', withDecimals: true })}`,
      style: 'dot',
    },
  ];

  return (
    <LayoutBox paddingX="40px" gap={16}>
      <Box width="100%">
        <PageTitleAndAction
          data={policy}
          HeaderTitle={policy.insured}
          showHeroIcon={false}
          status={policy.payoutsEnabled ? 'Active' : 'Inactive'}
          itemNumber={policy.policyNumber && `Policy No. #${policy.policyNumber}`}
          actionButtons={ActionButtons}
          expiration={{ text: `Expires ${moment(policy.effectiveUntil).format('MM/DD/YYYY')}` }}
          effectiveDate={policy.effectiveAt}
        />
      </Box>
      <LayoutBox width="100%" zIndex={-1}>
        <QuoteStat data={policyData} />
      </LayoutBox>
    </LayoutBox>
  );
};

const FeesSection = ({ fees }) => {
  return (
    <LayoutBox width="100%" backgroundColor="white" padding="24px" color="#212B36">
      <Box>
        <Text value="Fees" style={{ fontWeight: '700px', fontSize: '16px' }} color="#212B36"></Text>
      </Box>
      <LayoutBox style={{ display: 'flex', padding: ' 8px 0px', alignItems: 'center', gap: '12px', alignSelf: 'stretch' }} backgroundColor="white">
        {fees.map((fee) => {
          return (
            <LayoutBox row width="100%" backgroundColor="white" key={fee.name}>
              <Box flexDirection={'row'} width="50%">
                <Text value={fee.name} fontSize="14px" style={{ fontWeight: '500px' }}></Text>
              </Box>
              <Box flexDirection={'row'} width="50%">
                <Text value={`$${formatMoney(fee.amount)}`} fontSize="14px" style={{ fontWeight: '500px' }}></Text>
              </Box>
            </LayoutBox>
          );
        })}{' '}
      </LayoutBox>
    </LayoutBox>
  );
};

const AttachmentSection = ({ policy }) => {
  return (
    <LayoutBox
      style={{
        display: ' flex',
        padding: ' 24px',
        flexDirection: 'column',
        alignteIms: 'flex-start',
        gap: ' 16px',
        alignSelf: 'stretch',
        backgroundColor: 'white',
      }}
    >
      <Attachments policy={policy} attachments={policy.attachments} />
    </LayoutBox>
  );
};

const Policy = (props) => {
  const {
    currentTab,
    setCurrentTab = () => {},
    editMonthlyReporting,
    setEditMonthlyReporting,
    policy,
    monthlyReportingData,
    status,
    handleUpdateMonthlyReportingData,
    isUpdatingMonthly,
    handleResetMonthlyReportingData,
    fetchingPolicyDetails,
    MCEsData,
  } = usePolicyPageContext();

  const tabs = {
    COVERAGES: 'COVERAGES',
    MONTHLY_REPORTING: 'MONTHLY_REPORTING',
    MCES: 'MCES',
    ENDORSEMENTS: 'ENDORSEMENTS',
  };

  const sites = useMemo(() => {
    let sites = [];
    if (policy && policy.locations && Array.isArray(policy.locations) && policy.locations.length) {
      for (const location of policy.locations) {
        sites.push(location);
      }
    }
    return sites;
  }, [policy]);

  const Spinner = () => {
    return (
      <LayoutBox style={{ position: 'relative', minHeight: '100vh' }} width="100%">
        <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, margin: 'auto' }}>
          <ActivityIndicator />
        </Box>
      </LayoutBox>
    );
  };

  return (
    <>
      <NavBar>
        <Box width="100%" height={({ theme }) => theme.sizes.appBarHeight} />
        <NavRow>
          <NavGroup start flex={1}>
            <NavBackButton path="/policies" label={policy?.policyNumber ? `Policies/ #${policy.policyNumber}` : 'Back to Policies'} />
          </NavGroup>
        </NavRow>
      </NavBar>
      <Page backgroundColor="#F9FAFB">
        {status === 'pending' || fetchingPolicyDetails ? <Spinner /> : null}
        {status === 'error' ? <Text>Could not get policy information. Try refreshing the page.</Text> : null}
        {policy && monthlyReportingData && MCEsData ? (
          <LayoutBox width="100%" gap={40}>
            <SitesMap sites={sites} />
            <PolicyDetails
              policy={policy}
              currentTab={currentTab}
              setEditMonthlyReporting={setEditMonthlyReporting}
              editMonthlyReporting={editMonthlyReporting}
              handleUpdateMonthlyReportingData={handleUpdateMonthlyReportingData}
              handleResetMonthlyReportingData={handleResetMonthlyReportingData}
              isUpdatingMonthly={isUpdatingMonthly}
            />
            <PolicyTabs
              data={policy}
              productCoverages={policy.locations}
              policyTabsList={tabs}
              quotesView={false}
              monthlyReportingData={monthlyReportingData}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              MCEsData={MCEsData}
            />
            {currentTab === 'COVERAGES' && (
              <>
                {!!policy.fees.length && <FeesSection fees={policy.fees} />}
                <AttachmentSection policy={policy} />
              </>
            )}
          </LayoutBox>
        ) : null}
      </Page>
    </>
  );
};

const SitesMap = ({ sites }) => {
  if (!sites || !sites.length) {
    return null;
  }
  return (
    <LayoutBox width="100%" height={({ theme }) => theme.breakpoints({ xs: 300, sm: 300 })}>
      <LocationMap sites={sites} style={{ width: '100%', height: '100%' }} />
      <Box flexDirection="row" zIndex={99} justifyContent="center" alignItems="center" width="100%" position="absolute" paddingTop="250px">
        <Text xSmall bold uppercase padX="$2.25" padY="$1" bg="$brandOrange" onColor={'$brandOrange'} borderRadius={12} style={{ fontWeight: 600 }}>
          <Text fontWeight={300}>Station Status: </Text>Install ticket created
        </Text>
      </Box>
    </LayoutBox>
  );
};

const Attachments = ({ policy, attachments = [] }) => {
  const { id } = policy;
  const [inputValue, setInputValue] = useState(null);
  const lastInputValue = useRef(inputValue);

  const { uploadPolicyAttachment } = useApi();
  const uploadDocument = useCallback(
    async (document) => {
      await uploadPolicyAttachment(id, {
        document,
      });
      // await refreshApplicationData(true);
    },
    [uploadPolicyAttachment, id]
  );

  const { execute } = useAsync(uploadDocument, { immediate: false });

  useEffect(() => {
    // attachment upload
    if (inputValue && inputValue !== lastInputValue.current) {
      lastInputValue.current = inputValue;

      execute(inputValue);
    }
  }, [inputValue, execute]);

  attachments.sort((a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  });

  const tdProps = {
    labelProps: { size: 'small' },
    invert: true,
    flex: 1,
  };

  return (
    <LayoutBox>
      <LayoutBox row justifyContent="space-between" alignItems="flex-end">
        <Heading level={3} small weight="700px" bold>
          Attachments
        </Heading>
        <LayoutBox row layout="bottom-right" gap={12}>
          <FileInput
            value={inputValue}
            onChangeValue={(files) => {
              if (files.length) {
                setInputValue(files[0]);
              }
            }}
          />
        </LayoutBox>
      </LayoutBox>
      {!!attachments.length && <Spacing vertical={1.5} border={{ bottom: { width: 1, color: '$gray.200' } }} />}
      {attachments.map((attachment) => (
        <LayoutBox row flex={1} key={`${attachment.attachment_id}`}>
          <TextData label="Name" data={attachment.name} href={attachment.url} {...tdProps} textProps={{ color: '$primary' }} />
          <TextData label="Modified" data={moment.utc(attachment.lastModified).format('MM-DD-YYYY')} {...tdProps} />
        </LayoutBox>
      ))}
      {!!attachments.length && <Spacing vertical={5} />}{' '}
    </LayoutBox>
  );
};

const LocationMap = React.forwardRef((props, ref) => {
  const { sites, ...rest } = props;
  const site = sites.length ? sites[0] : null;
  const { bounds, maxBounds } = useMemo(() => {
    if (site && site.lat && site.lon) {
      const { lat, lon } = site;
      return {
        bounds: getBounds({ center: [lon, lat], hectares: 1 }),
        maxBounds: getBounds({ center: [lon, lat], hectares: 15 }),
      };
    }
    return { bounds: null, maxBounds: null };
  }, [site]);

  const markerCoordinate = useMemo(() => {
    return site ? [site.lon, site.lat] : null;
  }, [site]);

  if (!site) {
    return null;
  }
  return (
    <Map staticMap ref={ref} {...rest}>
      <Map.Camera bounds={bounds} maxBounds={maxBounds} setBoundsOnResize={false} animationDuration={400} />
      {markerCoordinate ? <Map.Marker id="mapmarker" coordinate={markerCoordinate} /> : null}
    </Map>
  );
});

export const PolicyPage = () => {
  return (
    <PolicyPageContextProvider>
      <Policy />
    </PolicyPageContextProvider>
  );
};
