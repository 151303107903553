import moment from 'moment-timezone';
import { Dialog, LayoutBox, NavBackButton, NavBar, NavGroup, NavRow, Page, TextData } from '../../components';
import PageTitleAndAction from '../../components/PageTitleAndActions';
import { useApi, useAsync, useNavigate } from '../../hooks';
import { Box, Button, FileInput, GridModal, Heading, Paragraph, Spacing, Text, TextField } from '../../ui';
import { QuoteStat } from '../QuotePage/content/QuoteView/QuoteStat';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import DropdownMenu from '../../components/DropdownMenu';
import { PolicyPageContextProvider, usePolicyPageContext } from '../PolicyPage/context/PolicyPageContext';
import { ExecuteMCEModal, LoaderModal, SaveDraftMCEModal } from '../../organisms/MCEModals';
import PDFViewer from '../PDFPage';
import { PolicyTabs } from '../PolicyPage/content/PolicyView/PolicyTabs';
import { ActivityIndicator } from 'react-native-web';
import { MCEPageContextProvider, useMCEPageContext } from './context/MCEPageContext';
import { capitalize } from '../../ui/utils';
import { currencyFormatter } from '../QuotePage/content/QuoteGrids/utils';
import { useParams } from 'react-router-dom';

const AddMCENote = memo(function AddMCENote(props) {
  const { id, notes = '', ...rest } = props.mce;
  const [note, setNote] = useState('');
  const [open, setOpen] = useState(false);
  const { setMCEDetails } = useMCEPageContext();

  const handleAddNote = useCallback(() => {
    setMCEDetails({ ...props.mce, notes: note });
    setOpen(false);
  }, [note]);

  useEffect(() => {
    if (open) {
      setNote(notes || '');
    }
  }, [open, notes]);

  return (
    <>
      <Button color="$primary.light" variant="text" label="Update" onPress={() => setOpen(true)} {...rest} />
      <Dialog
        open={open}
        heading="Update MCE Notes"
        onClose={() => setOpen(false)}
        actions={
          <>
            <Button label="Cancel" variant="text" color="$primary" onPress={() => setOpen(false)} />
            <Button label="Submit" onPress={() => handleAddNote(note)} disabled={!note.trim()} color={!note ? '$gray.500' : '$primary'} />
          </>
        }
      >
        <TextField
          InputProps={{ multiline: true }}
          multiline
          height={200}
          width="100%"
          maxWidth="100%"
          value={note}
          onChangeValue={(v) => setNote(v)}
        />
      </Dialog>
    </>
  );
});

const MCENotes = ({ mce, isEditing, policyId }) => (
  <Box>
    <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} backgroundColor="#fff" padding={24} borderRadius={8} gap={16}>
      <Heading level={5}>Notes</Heading>
      <Paragraph style={{ color: '#637381', fontWeight: '500' }}>{mce.notes || 'No MCE notes'}</Paragraph>
      {isEditing ? <AddMCENote id={mce.id} currentDescription={mce.notes} alignSelf="flex-start" mce={mce} policyId={policyId} /> : null}
    </Box>
  </Box>
);

const NewMiscPage = () => {
  const navigate = useNavigate();
  const scrollRef = useRef();
  const { UpdateMCE, downloadMCE, executeMCE } = useApi();
  const { policyId, MCEId } = useParams();

  const {
    coveragesData,
    setShouldShowWarning,
    shouldShowWarning,
    setShowSplitModal = () => {},
    showSplitModal,
    policy,
    status,
    monthlyReportingData,
  } = usePolicyPageContext();

  const {
    MCEDetails,
    isEditing,
    setIsEditing,
    premiumData,
    refreshMCE,
    setMCEDetails,
    showExecuteButton,
    MCEStatus,
    resetMCE,
    deletedLocations,
    setDeletedLocations,
    setPremiumData,
  } = useMCEPageContext();
  const [shouldShowDraftModal, setShouldShowDraftModal] = useState(false);
  const [shouldExecute, setShouldExecute] = useState(false);
  const [loading, setLoading] = useState(false);
  const tabs = {
    PREMIUM_CALCULATION: 'PREMIUM_CALCULATION',
    MCE_FEES: 'MCE_FEES',
  };

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsEditing(false);
    }, 3000);
  };

  const generateFormattedPayload = (premiumData) => {
    const updatedMCEDetails = { ...MCEDetails };

    updatedMCEDetails.calculations = updatedMCEDetails.calculations.map((location) => {
      const matchingPremium = premiumData.find((item) => item.locationId === location.locationId);
      if (matchingPremium) {
        return {
          ...location,
          declaredInventory: matchingPremium.avg_declared_inventory,
        };
      }
      return location;
    });

    updatedMCEDetails.locations = updatedMCEDetails.locations.map((location) => {
      const matchingLocation = premiumData.find((loc) => loc.locationId === location.id);
      if (matchingLocation) {
        const newDeclaredInventory = matchingLocation?.avg_declared_inventory || 0;
        const originalCoveredValue = location.coveredValue;

        location.coveredValue = newDeclaredInventory;

        // Update the payoutLimit in coverages to match the new coveredValue (only if it was equal to the original coveredValue)
        location.coverages = location.coverages.map((coverage) => {
          if (coverage.payoutLimit === originalCoveredValue) {
            coverage.payoutLimit = newDeclaredInventory;
          }
          return coverage;
        });

        if (matchingLocation?.isRowDeleted && matchingLocation.added !== MCEDetails.effectiveAt) {
          location.deleted = `${MCEDetails.effectiveAt} 00:00:00`;
        }
      }
      return location;
    });
    const newLocations = premiumData.filter((loc) => loc.newLocation).map(({ newLocation }) => ({ ...newLocation }));
    updatedMCEDetails.locations = [...updatedMCEDetails.locations, ...newLocations];
    return updatedMCEDetails;
  };

  const { execute: executeUpdate, status: updateStatus } = useAsync(UpdateMCE, { immediate: false });

  const handleSave = async () => {
    try {
      const formattedPayload = generateFormattedPayload(premiumData);
      await executeUpdate(policyId, MCEId, formattedPayload);
      setLoading(true);
      await refreshMCE();
    } catch (error) {
      console.error('Error saving data:', error);
      alert('Failed to save data. Please try again later.');
    } finally {
      setIsEditing(false);
      setDeletedLocations([]);
      setLoading(false);
    }
  };

  const handleExecuteMCE = async () => {
    try {
      await executeMCE(policyId, MCEId);
      setShouldExecute(false);
      setLoading(true);
      await refreshMCE();
    } catch (error) {
      console.error('Error executing MCE:', error);
      alert('Failed to execute MCE. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const ActionButtons = () => {
    const [open, setOpen] = useState(false);
    const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

    const handleCancelDelete = () => {
      try {
        if (deletedLocations.length > 0) {
          const updatedLocations = MCEDetails.locations.map((location) => {
            const wasDeleted = deletedLocations.some((deletedLocation) => deletedLocation.riskId === location.riskId);

            if (wasDeleted) {
              return { ...location, deleted: null };
            }
            return location;
          });

          setMCEDetails({
            ...MCEDetails,
            locations: updatedLocations,
          });

          setDeletedLocations([]);
        }
      } catch (error) {
        console.error('Failed to revert MCE details:', error);
        alert('An error occurred while reverting the MCE details. Please try again.');
      }
    };
    const handleDownloadMCE = async () => {
      setIsDownloadInProgress(true);
      try {
        if (MCEDetails.id) {
          const response = await downloadMCE(MCEDetails.id, policyId);
          if (response && response.request && response.request.responseURL) {
            const downloadUrl = response.request.responseURL;
            window.open(downloadUrl, '_blank');
          } else {
            alert('Failed to download the MCE. Please try again.');
          }
        } else {
          alert('Please select an MCE to download.');
        }
      } catch (error) {
        alert('An error occurred while trying to download the MCE. Please try again.');
        console.error(error);
      } finally {
        setIsDownloadInProgress(false);
      }
    };

    return (
      <LayoutBox row layout="center-right" gap={12}>
        <Button
          target="_blank"
          color="$primary"
          rel="noreferrer"
          onPress={handleDownloadMCE}
          borderRadius={8}
          loading={isDownloadInProgress}
          disabled={isDownloadInProgress}
        >
          Download
        </Button>
        {isEditing && (
          <LayoutBox row layout="center-right" gap={12}>
            <Button
              target="_blank"
              color="$primary"
              rel="noreferrer"
              onPress={() => {
                setIsEditing(false);
                resetMCE();
              }}
              borderRadius={8}
            >
              Cancel
            </Button>
            <Button
              loading={updateStatus === 'pending'}
              disabled={updateStatus === 'pending'}
              target="_blank"
              color="$primary"
              rel="noreferrer"
              onPress={() => {
                if (!!deletedLocations.length) {
                  setOpen(true);
                } else handleSave();
              }}
              borderRadius={8}
            >
              Save
            </Button>
          </LayoutBox>
        )}
        {!isEditing && (
          <Button
            target="_blank"
            color="$primary"
            rel="noreferrer"
            onPress={() => {
              setIsEditing(true);
            }}
            borderRadius={8}
          >
            Edit
          </Button>
        )}
        {showExecuteButton && (
          <Button
            target="_blank"
            color="$primary"
            borderRadius={8}
            onPress={() => {
              setShouldExecute(true);
            }}
            label="Execute"
          ></Button>
        )}
        <DropdownMenu options={[{ label: 'Duplicate' }, { label: 'Delete' }]} />

        <GridModal
          open={open}
          title="Are You Sure You Want to Delete?"
          onClose={() => {
            setOpen(false);
          }}
          buttonText="Delete Permanently"
          deleteModal={true}
          handlePress={handleSave}
          capitalizeTitle={true}
          onCancel={handleCancelDelete}
        >
          <Text align="left" size="large" style={{ fontWeight: 500, padding: 16 }}>
            This action cannot be undone.
          </Text>
        </GridModal>
      </LayoutBox>
    );
  };

  const MiscOverViewSection = ({ policy }) => {
    const { effectiveAt, effectiveUntil, status, mceNumber, netPremium } = MCEDetails;
    const MCEStatsData = [
      {
        title: 'Additional premium',
        text: ` ${currencyFormatter({ field: { value: netPremium }, withDecimals: true })}`,
      },
      {
        title: 'Effective At',
        text: `${effectiveAt}`,
      },
      {
        title: 'Effective Until',
        text: `${effectiveUntil || moment.utc(policy.effectiveUntil).format('YYYY-MM-DD')}`,
      },
    ];

    return (
      <LayoutBox paddingTop="40px" paddingX="40px" gap={16}>
        <Box width="100%">
          <PageTitleAndAction
            data={policy}
            HeaderTitle={policy?.insured}
            showHeroIcon={false}
            status={capitalize(status)}
            itemNumber={mceNumber}
            actionButtons={ActionButtons}
            expiration={{ text: `Expires ${moment(effectiveUntil).format('MM/DD/YYYY')}` }}
            effectiveDate={moment(effectiveAt)}
            type={MCEDetails.type}
          />
        </Box>
        <LayoutBox width="100%" zIndex={-1}>
          <QuoteStat data={MCEStatsData} />
        </LayoutBox>
        <LayoutBox gap={18}>
          <MCENotes mce={MCEDetails} isEditing={isEditing} policyId={policy.id} />
        </LayoutBox>
      </LayoutBox>
    );
  };

  const AttachmentSection = ({ policy }) => {
    return (
      <LayoutBox
        style={{
          display: ' flex',
          padding: ' 24px',
          flexDirection: 'column',
          alignteIms: 'flex-start',
          gap: ' 16px',
          alignSelf: 'stretch',
          backgroundColor: 'white',
        }}
      >
        <Attachments policy={{}} attachments={policy?.attachments} />
      </LayoutBox>
    );
  };

  const Attachments = ({ policy, attachments = [] }) => {
    const { id } = policy;
    const [inputValue, setInputValue] = useState(null);
    const lastInputValue = useRef(inputValue);

    const { uploadPolicyAttachment } = useApi();
    const uploadDocument = useCallback(
      async (document) => {
        await uploadPolicyAttachment(id, {
          document,
        });
        // await refreshApplicationData(true);
      },
      [uploadPolicyAttachment, id]
    );

    const { execute } = useAsync(uploadDocument, { immediate: false });

    useEffect(() => {
      // attachment upload
      if (inputValue && inputValue !== lastInputValue.current) {
        lastInputValue.current = inputValue;

        execute(inputValue);
      }
    }, [inputValue, execute]);

    attachments.sort((a, b) => {
      if (a.name > b.name) return 1;
      else if (a.name < b.name) return -1;
      else return 0;
    });

    const tdProps = {
      labelProps: { size: 'small' },
      invert: true,
      flex: 1,
    };

    return (
      <LayoutBox>
        <LayoutBox row justifyContent="space-between" alignItems="flex-end">
          <Heading level={3} small weight="700px" bold>
            Attachments
          </Heading>
          <LayoutBox row layout="bottom-right" gap={12}>
            <FileInput
              value={inputValue}
              onChangeValue={(files) => {
                if (files.length) {
                  setInputValue(files[0]);
                }
              }}
            />
          </LayoutBox>
        </LayoutBox>
        {!!attachments.length && <Spacing vertical={1.5} border={{ bottom: { width: 1, color: '$gray.200' } }} />}
        {attachments.map((attachment) => (
          <LayoutBox row flex={1} key={`${attachment.attachment_id}`}>
            <TextData label="Name" data={attachment.name} href={attachment.url} {...tdProps} textProps={{ color: '$primary' }} />
            <TextData label="Modified" data={moment(attachment.lastModified).format('MM-DD-YYYY')} {...tdProps} />
          </LayoutBox>
        ))}
        {!!attachments.length && <Spacing vertical={5} />}
      </LayoutBox>
    );
  };

  const handleAddLocation = () => {
    setIsEditing(true);
  };

  const handleNavigate = () => {
    if (isEditing) setShouldShowDraftModal(true);
    else {
      setShouldShowDraftModal(false);
      setIsEditing(false);
      navigate.back(-1);
    }
  };

  const Spinner = () => {
    return (
      <LayoutBox style={{ position: 'relative', minHeight: '100vh' }} width="100%">
        <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, margin: 'auto' }}>
          <ActivityIndicator />
        </Box>
      </LayoutBox>
    );
  };

  return (
    <LayoutBox width="100%" ref={scrollRef}>
      <NavBar>
        <Box width="100%" height={({ theme }) => theme.sizes.appBarHeight} />
        <NavRow>
          <NavGroup start flex={1}>
            <NavBackButton onPress={() => handleNavigate()} label={'Back to Policy'} />
          </NavGroup>
        </NavRow>
      </NavBar>
      <Page backgroundColor="#F9FAFB" ref={scrollRef}>
        {status === 'pending' ? <Spinner /> : null}
        {status === 'error' ? <Text>Could not get policy information. Try refreshing the page.</Text> : null}
        {policy && monthlyReportingData && MCEDetails && (
          <LayoutBox width="100%" gap={40}>
            <MiscOverViewSection policy={policy} />
            {MCEStatus !== 'Executed' ? (
              <PolicyTabs
                data={policy}
                MCEDetails={MCEDetails}
                productCoverages={coveragesData}
                policyTabsList={tabs}
                quotesView={false}
                shouldShowWarning={shouldShowWarning}
                handleAddLocation={handleAddLocation}
                setShouldShowWarning={setShouldShowWarning}
                setShowSplitModal={setShowSplitModal}
                showSplitModal={showSplitModal}
                monthlyReportingData={monthlyReportingData}
                isEditingMCE={isEditing}
                setMCEDetails={setMCEDetails}
                UpdateMCE={UpdateMCE}
                setDeletedLocations={setDeletedLocations}
                setPremiumData={setPremiumData}
                premiumData={premiumData}
              />
            ) : (
              !showExecuteButton && <PDFViewer />
            )}
            <AttachmentSection policy={policy} />
            {shouldShowDraftModal && (
              <SaveDraftMCEModal
                showModal={shouldShowDraftModal}
                setShowModal={setShouldShowDraftModal}
                handleDiscardChanges={() => {
                  setShouldShowDraftModal(false);
                  navigate.back(-1);
                }}
                onClose={() => {
                  setShouldShowDraftModal(false);
                }}
                handlePress={() => {
                  handleClick();
                  setShouldShowDraftModal(false);
                  navigate.back(-1);
                }}
              />
            )}
            {loading && <LoaderModal loading={loading} setLoading={setLoading} />}
            <ExecuteMCEModal
              showModal={shouldExecute}
              setShowModal={setShouldExecute}
              handlePress={handleExecuteMCE}
              loading={updateStatus === 'pending'}
            />
          </LayoutBox>
        )}
      </Page>
    </LayoutBox>
  );
};

export const MCEPage = () => {
  return (
    <PolicyPageContextProvider>
      <MCEPageContextProvider>
        <NewMiscPage />
      </MCEPageContextProvider>
    </PolicyPageContextProvider>
  );
};
