import React, { useEffect, useMemo, useState } from 'react';
import { LayoutBox } from '../../../components';
import { View } from 'react-native-web';
import { usePolicyPageContext } from '../../PolicyPage/context/PolicyPageContext';
import { Box, Button, Grid, GridModal, Text } from '../../../ui';
import { currencyFormatter } from '../../QuotePage/content/QuoteGrids/utils';
import { useMCEPageContext } from '../context/MCEPageContext';
import moment from 'moment-timezone';
import { isEmpty } from '../../../ui/utils';
import { LocationDialog } from '../../SubmitApplicationPage/LocationDialog';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../hooks';
import { getDefaultCoverages } from '../../../utils';

const SplitInventoryModal = ({ isOpen, onClose, onUpdate, originalData = [], setGridReference, setShowSplitModal }) => {
  const [rowData, setRowData] = useState([]);
  const [oldSum, setOldSum] = useState(0);
  const [newSum, setNewSum] = useState(0);
  const [exceedsLimit, setExceedsLimit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const columnDefs = [
    { headerName: 'Risk ID', field: 'riskId', editable: false },
    { headerName: 'Name', field: 'name', editable: false },
    { headerName: 'Address', field: 'address', editable: false },
    { headerName: 'Bound Inventory', field: 'boundInventory', editable: false },
    {
      headerName: 'Average Declared Inventory',
      field: 'avg_declared_inventory',
      editable: true,
      valueSetter: (params) => {
        const newValue = Number(params.newValue);
        params.data.avg_declared_inventory = newValue;
        calculateNewSum(rowData);
        return true;
      },
      cellClassRules: {
        'error-cell': (params) => exceedsLimit,
      },
    },
  ];

  const calculateNewSum = (data) => {
    const sum = data.reduce((acc, item) => acc + (Number(item.avg_declared_inventory) || 0), 0);
    setNewSum(sum);
    setExceedsLimit(sum > oldSum);
    setErrorMessage(sum !== oldSum ? 'The sum of new values must equal the sum of old values.' : '');
  };

  useEffect(() => {
    if (isOpen) {
      originalData.length && setRowData(originalData);
      const sum = originalData.reduce((acc, item) => acc + (Number(item.avg_declared_inventory) || 0), 0);
      setOldSum(sum);
    }
  }, [isOpen, originalData]);

  const handleUpdate = () => {
    if (newSum !== oldSum) {
      setErrorMessage('The sum of new values must equal the sum of old values.');
      return;
    }
    onUpdate(rowData);
    setShowSplitModal(false);
  };

  return (
    <GridModal
      open={isOpen}
      title="Split Inventory"
      onClose={onClose}
      buttonText="Update Inventory"
      deleteModal={false}
      handlePress={handleUpdate}
      capitalizeTitle={true}
      width="900px"
      disabled={exceedsLimit}
    >
      {rowData.length > 0 ? (
        <>
          <LayoutBox
            row
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text
              style={{
                color: '#212B36',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '10px',
                flex: '1 0 0',
              }}
            >
              {`Average Declared Inventory Sum: $${oldSum.toLocaleString()}`}
            </Text>
            {!!newSum && (
              <Text
                style={{
                  color: exceedsLimit ? 'red' : '#212B36',
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  paddingRight: '10px',
                  flex: '1 0 0',
                  animation: exceedsLimit ? 'blink 1s infinite' : 'none',
                }}
              >
                {`Updated Inventory Sum: $${newSum.toLocaleString()}`}
              </Text>
            )}
          </LayoutBox>
          <View
            style={{
              minHeight: '100px',
              maxHeight: '500px',
              width: '100%',
              padding: 16,
              marginTop: 10,
              backgroundColor: '#fff',
              borderRadius: 8,
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.2,
              shadowRadius: 2,
              elevation: 2,
            }}
          >
            {/* Conditional Rendering: Check if there is any rowData */}
            <Grid
              data={rowData}
              columnDefs={columnDefs}
              domLayout="autoHeight"
              onCellValueChanged={(params) => {
                calculateNewSum(rowData);
              }}
              setGridReference={setGridReference}
              floatingFilter={false}
              shouldShowPagination={false}
            />
          </View>
        </>
      ) : (
        <Text style={{ color: '#555', padding: '20px', textAlign: 'center' }}>No rows selected. Please select rows to distribute inventory.</Text>
      )}
      {errorMessage && <Text style={{ color: 'red', paddingTop: '10px' }}>{errorMessage}</Text>}
    </GridModal>
  );
};

const sumValues = (values) => {
  return values.reduce((sum, value) => {
    const numericValue = typeof value === 'string' && !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    if (typeof numericValue === 'number') {
      return sum + numericValue;
    }
    return sum;
  }, 0);
};

export const PremiumCalculation = React.memo((props) => {
  const { setGridReference, onSelectionChanged } = props;
  const [rowData, setRowData] = useState([]);
  const { setShowSplitModal = () => {}, showSplitModal, policy } = usePolicyPageContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const { MCEDetails, isEditing, monthlyData, setPremiumData, setIsEditing, premiumData, setMCEDetails } = useMCEPageContext();
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [inProgess, setInProgress] = useState(false);
  const { addMCELocation } = useApi();
  const { policyId } = useParams();
  let updateQueue = [];
  let batchUpdateTimer;

  const onRowSelected = (event) => {
    if (event.node.selected) {
      setSelectedRows((prev) => {
        const alreadySelected = prev.some((row) => row.riskId === event.data.riskId);
        if (!alreadySelected) {
          return [...prev, event.data];
        }
        return prev;
      });
    } else {
      setSelectedRows((prev) => prev.filter((row) => row.riskId !== event.data.riskId));
    }
  };

  const handleModalUpdate = (updatedValues) => {
    updatedValues.forEach((newValue) => {
      const rowIndex = rowData.findIndex((row) => row.riskId === newValue.riskId);
      if (rowIndex !== -1) {
        rowData[rowIndex].avg_declared_inventory = newValue.avg_declared_inventory;
      }
    });
    setRowData([...rowData]);
    setPremiumData([...rowData]);
  };

  const createColumns = useMemo(() => {
    const baseColumns = [
      {
        headerName: 'RiskId',
        field: 'riskId',
        minWidth: 100,
        checkboxSelection: (params) => params.data?.riskId && isEditing && params.data.deleted === 'No' && !params.data?.isRowDeleted,
      },
      { headerName: 'Name', field: 'name', minWidth: 150 },
      {
        headerName: 'Address',
        field: 'address',
        minWidth: 150,
        cellClassRules: {
          'blue-background': (params) => {
            return ['% of Bound', 'Total'].includes(params.data.address);
          },
        },
      },
      ...(MCEDetails?.type !== 'retrospective'
        ? [
            { headerName: 'Added', field: 'added', minWidth: 200 },
            { headerName: 'Deleted', field: 'deleted', minWidth: 200 },
          ]
        : []),
      {
        headerName: `Bound Inventory - ${MCEDetails?.effectiveAt}`, // Dynamic header for policy effective date
        field: 'boundTiv',
        minWidth: 150,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') return;
          if (params.data.address === '% of Bound') {
            return params.value ? `${params.value.toFixed(2)}%` : '';
          }
          return !isEmpty(params.data) ? currencyFormatter({ field: params, withDecimals: true }) : '';
        },
        cellStyle: (params) => {
          return {
            fontWeight: 'bold',
          };
        },
      },
    ];

    const monthlyColumns =
      monthlyData && monthlyData?.months && MCEDetails?.type === 'retrospective'
        ? monthlyData?.months?.map((date, index) => {
            return {
              headerName: date,
              field: `monthlyInventory_${index}`,
              minWidth: 150,
              valueFormatter: (params) => {
                if (typeof params.value === 'string') return;
                if (params.data.address === '% of Bound') {
                  return params.value ? `${params.value.toFixed(2)}%` : '';
                }
                return !isEmpty(params.data) ? currencyFormatter({ field: params, withDecimals: true }) : '';
              },
              valueSetter: (params) => {
                params.data[`monthlyInventory_${index}`] = params.newValue;
              },
            };
          })
        : [];

    const additionalColumns = [
      {
        headerName: 'Average Declared Inventory',
        field: 'avg_declared_inventory',
        minWidth: 150,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') return;
          if (params.data.address === '% of Bound') {
            return !isEmpty(params.data) ? `${params.value.toFixed(2)}%` : '';
          }
          return !isEmpty(params.data) ? currencyFormatter({ field: params, withDecimals: true }) : '';
        },
        valueSetter: (params) => {
          const newValue = Number(params.newValue);
          params.data.avg_declared_inventory = newValue;
          setPremiumData((prevData) =>
            prevData.map((row) => (row.locationId === params.data.locationId ? { ...row, avg_declared_inventory: newValue } : row))
          );

          return true;
        },
        cellClassRules: {
          'ag-cell-editable': (params) => params.data.address !== 'Total' && params.data.address !== '% of Bound' && !isEmpty(params.data),
          'ag-cell-disable': (params) =>
            !isEmpty(params.data) && params.data.address !== 'Total' && params.data.address !== '% of Bound' && params.data.deleted !== 'No',
        },
        editable: (params) => isEditing && !isEmpty(params.data) && params.data.deleted === 'No',
        cellStyle: (params) => {
          if (params.data.address === 'Total') {
            return {
              fontWeight: 'bold',
            };
          }
          return null;
        },
        dataType: 'number',
      },
      {
        headerName: 'Inventory Difference',
        field: 'inventoryDifference',
        minWidth: 150,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') return;
          if (params.data.address === '% of Bound') {
            return params.value ? `${params.value.toFixed(2)}%` : '';
          }
          return !isEmpty(params.data) ? currencyFormatter({ field: params, withDecimals: true }) : '';
        },
        cellStyle: (params) => {
          if (params.data.address === 'Total') {
            return {
              fontWeight: 'bold',
              color: params.value < 0 ? 'red' : '',
            };
          } else if (params.value < 0) {
            return { color: 'red' };
          }
          return null;
        },
      },
      {
        headerName: 'Rate',
        field: 'rate',
        minWidth: 150,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') return;
          if (params.data.address === '% of Bound') {
            return params.value ? `${params.value.toFixed(2)}%` : '';
          }
          return !isNaN(parseInt(params.value)) ? ` $ ${params.value}` : '';
        },
        cellStyle: (params) => {
          if (params.data.address === 'Total') {
            return {
              fontWeight: 'bold',
            };
          } else if (params.value < 0) {
            return { color: 'red' };
          }
          return null;
        },
      },
      {
        headerName: 'Percent of Year',
        field: 'percOfYear',
        minWidth: 150,
        valueFormatter: (params) => (params.value ? params.value.toString() : ''),
      },
      {
        headerName: 'Days',
        field: 'days',
        minWidth: 150,
        valueFormatter: (params) => (params.value ? params.value.toString() : ''),
      },
      {
        headerName: 'Premium',
        field: 'premium',
        minWidth: 150,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') return;
          if (params.data.address === '% of Bound') {
            return params.value ? `${params.value.toFixed(2)}%` : '';
          }
          return !isEmpty(params.data) ? currencyFormatter({ field: params, withDecimals: true }) : '';
        },
        cellStyle: (params) => {
          if (params.data.address === 'Total') {
            return {
              fontWeight: 'bold',
            };
          } else if (params.value < 0) {
            return { color: 'red' };
          }
          return null;
        },
      },
    ];

    return [...baseColumns, ...monthlyColumns, ...additionalColumns];
  }, [monthlyData, MCEDetails, isEditing, setPremiumData]);

  const getFormattedNewRow = (location) => {
    const { address, coveredValue, locationId, name, riskId, ...rest } = location;
    return {
      added: null,
      address,
      avg_declared_inventory: coveredValue,
      boundTiv: null,
      deleted: 'No',
      formatter: true,
      inventoryDifference: 0,
      isRowDeleted: false,
      locationId,
      name,
      percOfYear: 0,
      period: null,
      premium: 0,
      rate: 0,
      riskId,
      newLocation: location,
    };
  };

  const mapRowData = ({ monthlyData, MCEDetails }) => {
    if (!monthlyData || !monthlyData.locations || !monthlyData.months) {
      console.error('monthly data is not properly defined:', monthlyData);
      return [];
    }

    const { type } = MCEDetails || {};

    const baseRowData =
      MCEDetails?.locations?.map((location) => {
        const matchingLocation = monthlyData?.locations?.find((loc) => loc.riskId === location.riskId);
        const matchingCalculation = MCEDetails?.calculations?.find((loc) => loc.locationId === location.id);
        const monthlyValues =
          type !== 'locationChange' && matchingLocation
            ? monthlyData?.months?.reduce((acc, _, index) => {
                acc[`monthlyInventory_${index}`] = matchingLocation.values?.[index] || 0;
                return acc;
              }, {})
            : {};

        const { declaredInventory, difference, boundInventory, rate, premium, percOfYear } = matchingCalculation || {};
        return {
          locationId: location.id,
          added: location?.added ? moment.utc(location.added).format('YYYY-MM-DD') : null,
          deleted: location.deleted ? moment.utc(location.deleted).format('YYYY-MM-DD') : 'No',
          name: location.name,
          address: location.address,
          formatter: true,
          riskId: location.riskId,
          boundTiv: boundInventory,
          avg_declared_inventory: declaredInventory || location.coveredValue,
          inventoryDifference: difference || 0,
          rate: rate || 0,
          period: monthlyData?.months?.length || 0,
          days: Math.ceil(percOfYear * 365),
          percOfYear: percOfYear?.toFixed(4) || 0,
          premium: premium || 0,
          ...monthlyValues,
        };
      }) || [];

    const totalRow = {
      address: 'Total',
      boundTiv: baseRowData.reduce((sum, row) => sum + (row.boundTiv || 0), 0),
      avg_declared_inventory: baseRowData.reduce((sum, row) => sum + (row.avg_declared_inventory || 0), 0),
      inventoryDifference: baseRowData.reduce((sum, row) => sum + (row.inventoryDifference || 0), 0),
      rate: '',
      premium: MCEDetails?.netPremium,
      ...(type === 'retrospective' && {
        ...monthlyData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = sumValues(baseRowData.map((row) => row[`monthlyInventory_${index}`] || 0));
          return acc;
        }, {}),
      }),
    };

    const percentageRow = {
      address: '% of Bound',
      formatter: true,
      boundTiv: totalRow.boundTiv ? (totalRow.boundTiv / totalRow.boundTiv) * 100 : 0, // Prevent division by zero
      ...(type === 'retrospective' && {
        ...monthlyData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = totalRow.boundTiv ? ((totalRow[`monthlyInventory_${index}`] || 0) / totalRow.boundTiv) * 100 : 0; // Default to 0 if undefined
          return acc;
        }, {}),
      }),
      avg_declared_inventory: totalRow.boundTiv ? ((totalRow.avg_declared_inventory || 0) / totalRow.boundTiv) * 100 : 0,
      inventoryDifference: totalRow.boundTiv ? ((totalRow.inventoryDifference || 0) / totalRow.boundTiv) * 100 : 0,
      rate: totalRow.boundTiv ? ((totalRow.rate || 0) / totalRow.boundTiv) * 100 : 0,
      premium: totalRow.boundTiv ? ((MCEDetails.netPremium || 0) / policy.netPremium) * 100 : 0,
    };
    return [...baseRowData, {}, totalRow, percentageRow];
  };

  useEffect(() => {
    if (MCEDetails) {
      setPremiumData(mapRowData({ monthlyData, MCEDetails }));
    }
  }, [monthlyData, MCEDetails]);

  const handleSubmit = async (data) => {
    setInProgress(true);
    try {
      const currentTime = moment().utc().format('YYYY-MM-DD');
      const newLocation = await addMCELocation(policyId, { ...data, added: currentTime });
      if (!newLocation || newLocation.error) {
        console.error('Failed to add location:', newLocation?.error);
        alert('Failed to add location. Please try again.');
        return;
      }

      setShowAddLocationModal(false);

      newLocation['coverages'] = getDefaultCoverages(MCEDetails, newLocation);
      newLocation['reinsurance'] = {
        ...newLocation.reinsurance,
        locationId: newLocation.id,
        maxTotalInsuredValue: newLocation.coveredValue,
      };
      newLocation['requirements'] = { ...newLocation.requirements, locationId: newLocation.id };
      newLocation.added = null;
      newLocation.deleted = null;
      const newPremiumRow = getFormattedNewRow(newLocation);

      setPremiumData((pre) => {
        const index = pre.findIndex((item) => Object.keys(item).length === 0);

        if (index !== -1) {
          return [...pre.slice(0, index), newPremiumRow, ...pre.slice(index)];
        }

        return [...pre, newPremiumRow];
      });
    } catch (error) {
      alert('An unexpected error occurred. Please try again later.');
    } finally {
      setInProgress(false);
    }
  };

  const processBatchUpdates = () => {
    if (updateQueue.length === 0) return;
    const updatedRowIds = new Set(updateQueue.map((update) => update.rowId));
    updateQueue = [];
    setPremiumData((prevRowData) => {
      const totalRow = prevRowData.find((row) => row.address === 'Total') || {};
      const percentageRow = prevRowData.find((row) => row.address === '% of Bound') || {};

      const newTotalRow = {
        ...totalRow,
        boundTiv: calculateFieldSum(prevRowData, 'boundTiv', updatedRowIds),
        avg_declared_inventory: calculateFieldSum(prevRowData, 'avg_declared_inventory', updatedRowIds),
        inventoryDifference: calculateFieldSum(prevRowData, 'inventoryDifference', updatedRowIds),
        premium: calculateFieldSum(prevRowData, 'premium', updatedRowIds),
      };

      const newPercentageRow = {
        ...percentageRow,
        boundTiv: newTotalRow.boundTiv ? (newTotalRow.boundTiv / newTotalRow.boundTiv) * 100 : 0,
        avg_declared_inventory: newTotalRow.boundTiv ? (newTotalRow.avg_declared_inventory / newTotalRow.boundTiv) * 100 : 0,
        inventoryDifference: newTotalRow.boundTiv ? (newTotalRow.inventoryDifference / newTotalRow.boundTiv) * 100 : 0,
        premium: newTotalRow.boundTiv ? (newTotalRow.premium / newTotalRow.boundTiv) * 100 : 0,
      };

      // Update only the affected rows
      return prevRowData.map((row) => {
        if (row.address === 'Total') return newTotalRow;
        if (row.address === '% of Bound') return newPercentageRow;
        return row;
      });
    });
  };

  const calculateFieldSum = (rowData, field, updatedRowIds) =>
    rowData.reduce((sum, row) => {
      if (row.address === 'Total' || row.address === '% of Bound') return sum;
      if (updatedRowIds.has(row.locationId)) return sum + (row[field] || 0);
      return sum + (row[field] || 0);
    }, 0);

  const onCellValueChanged = (params) => {
    const updatedRow = { ...params.data, [params.colDef.field]: params.newValue };

    updateQueue.push(updatedRow);

    clearTimeout(batchUpdateTimer);

    batchUpdateTimer = setTimeout(() => {
      processBatchUpdates();
    }, 100);
  };

  const rowClassRules = {
    'ag-cell-disable': (params) =>
      !isEmpty(params.data) &&
      ((params.data.address !== 'Total' && params.data.address !== '% of Bound' && params.data.deleted !== 'No') || params.data.isRowDeleted),
  };

  return (
    <div className="ag-theme-quartz" style={{ minHeight: '500px' }}>
      <Grid
        data={premiumData}
        columns={createColumns}
        onRowSelected={onRowSelected}
        floatingFilter={false}
        setGridReference={setGridReference}
        shouldShowPagination={false}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        onCellValueChanged={onCellValueChanged}
        rowClassRules={rowClassRules}
      />
      {isEditing && (
        <LayoutBox row justifyContent="space-between" width="full" backgroundColor="white">
          <Box display="flex" alignItems="start" justifyContent="center" flexWrap="wrap" height={70}>
            <Box border="1px solid #919EAB52" borderRadius={8} marginLeft="$2">
              <Button
                color="#000"
                variant="text"
                label="Add Location"
                paddingX={16}
                onPress={() => {
                  setShowAddLocationModal(true);
                }}
              />
            </Box>
          </Box>
        </LayoutBox>
      )}
      <LocationDialog
        open={showAddLocationModal}
        onClose={() => {
          setShowAddLocationModal(false);
          // setLocationData([]);
          // setMode('CREATE');
        }}
        onSubmit={handleSubmit}
        saveButtonLabel={'Add Location'}
        canEdit={true}
        mode={'CREATE'}
        product={policy?.product}
        // data={locationData}
        shouldAddRiskId={true}
        locations={MCEDetails?.locations}
        processing={inProgess}
      />

      {showSplitModal && (
        <SplitInventoryModal
          isOpen={showSplitModal}
          onClose={() => {
            setShowSplitModal(false);
            setSelectedRows([]);
            setIsEditing(false);
          }}
          setShowSplitModal={setShowSplitModal}
          onUpdate={handleModalUpdate}
          originalData={selectedRows}
          setGridReference={setGridReference}
          floatingFilter={false}
          shouldShowPagination={false}
        />
      )}
    </div>
  );
});
