import { ContentCard } from '../../../../components';
import { Spacing } from '../../../../ui';
// import { QuoteDetails } from './QuoteDetails';
import { NewQuoteDetails } from './NewQuoteDetails';

export const QuoteView = ({ data, productCoverages, macavity, printRef }) => {
  return (
    <ContentCard pt="$8" padTop="$2.5" padBottom="$3.5" alignItems="stretch" bg="#F9FAFB" zIndex={-10} border={'none'}>
      <Spacing vertical={1} />

      <NewQuoteDetails ref={printRef} quote={data} productCoverages={productCoverages} macavity={macavity} />
      {/* <QuoteDetails ref={printRef} quote={data} productCoverages={productCoverages} macavity={macavity} concentrations={concentrations} /> */}
    </ContentCard>
  );
};
