import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi, useAsync } from '../../../hooks';
import { useParams } from 'react-router-dom';

const PolicyPageContext = createContext();

export const PolicyPageContextProvider = ({ children }) => {
  const { policyId } = useParams();
  const { getPolicyWithId, getPolicyMonthlyReportingData, getPolicyMCEs, updatePolicyMonthlyReportingData } = useApi();
  const [shouldShowWarning, setShouldShowWarning] = useState(true);
  const [locationsData, setLocationsData] = useState([]);
  const [coveragesData, setCoveragesData] = useState([]);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [editMonthlyReporting, setEditMonthlyReporting] = useState(false);
  const [fetchingPolicyDetails, setIsFetchingPolicyDetails] = useState(false);
  const [monthlyReportingData, setMonthlyReportingData] = useState([]);
  const [initialMonthlyReportingData, setInitialMonthlyReportingData] = useState([]);
  const [isUpdatingMonthly, setIsUpdatingMonthly] = useState(false);
  const [policyData, setPolicyData] = useState(null);
  const [MCEsData, setMCEsData] = useState(null);

  const handleGetPolicyData = useCallback(async () => {
    if (policyData) {
      return policyData;
    }

    setIsFetchingPolicyDetails(true);
    try {
      const policy = await getPolicyWithId(policyId);
      const monthlyData = await getPolicyMonthlyReportingData(policyId);
      const MCEs = await getPolicyMCEs(policyId);

      const fetchedData = { policy, monthlyData, MCEs };
      setPolicyData(fetchedData);
      setMCEsData(MCEs);
      return fetchedData;
    } catch (error) {
      console.error('Error fetching policy data:', error);
    } finally {
      setIsFetchingPolicyDetails(false);
    }
  }, [policyId, getPolicyWithId, getPolicyMonthlyReportingData, getPolicyMCEs, policyData]);

  const handleUpdateMonthlyReportingData = useCallback(async () => {
    setIsUpdatingMonthly(true);
    try {
      const response = await updatePolicyMonthlyReportingData(policyId, monthlyReportingData);
      if (response) {
        setMonthlyReportingData(JSON.parse(JSON.stringify(response)));
        setInitialMonthlyReportingData(JSON.parse(JSON.stringify(response)));
      }
    } catch (error) {
      console.error('Failed to update monthly reporting data:', error);
    } finally {
      setEditMonthlyReporting(false);
      setIsUpdatingMonthly(false);
    }
  }, [policyId, monthlyReportingData, updatePolicyMonthlyReportingData]);

  const handleResetMonthlyReportingData = useCallback(() => {
    setMonthlyReportingData(JSON.parse(JSON.stringify(initialMonthlyReportingData)));
  }, [initialMonthlyReportingData]);

  const { value, status } = useAsync(handleGetPolicyData);

  const { policy, monthlyData } = value || {};

  useEffect(() => {
    if (monthlyData) {
      setMonthlyReportingData(JSON.parse(JSON.stringify(monthlyData)));
      setInitialMonthlyReportingData(JSON.parse(JSON.stringify(monthlyData)));
    }
  }, [monthlyData]);

  const context = useMemo(
    () => ({
      shouldShowWarning,
      setShouldShowWarning,
      locationsData,
      setLocationsData,
      coveragesData,
      setCoveragesData,
      showSplitModal,
      setShowSplitModal,
      currentTab,
      setCurrentTab,
      editMonthlyReporting,
      setEditMonthlyReporting,
      policy,
      status,
      MCEsData,
      monthlyReportingData,
      setMonthlyReportingData,
      handleUpdateMonthlyReportingData,
      handleResetMonthlyReportingData,
      isUpdatingMonthly,
      initialMonthlyReportingData,
      fetchingPolicyDetails,
      setIsUpdatingMonthly,
      setMCEsData,
    }),
    [
      policy,
      status,
      MCEsData,
      monthlyReportingData,
      editMonthlyReporting,
      isUpdatingMonthly,
      setEditMonthlyReporting,
      shouldShowWarning,
      showSplitModal,
      setShowSplitModal,
      currentTab,
      setCurrentTab,
      setMonthlyReportingData,
      handleResetMonthlyReportingData,
      setIsUpdatingMonthly,
      fetchingPolicyDetails,
      coveragesData,
      handleUpdateMonthlyReportingData,
      initialMonthlyReportingData,
      locationsData,
      setMCEsData,
    ]
  );

  return <PolicyPageContext.Provider value={context}>{children}</PolicyPageContext.Provider>;
};

export const usePolicyPageContext = () => {
  const context = useContext(PolicyPageContext);
  if (!context) {
    throw new Error('usePolicyPageContext must be used within a PolicyPageContextProvider');
  }
  return context;
};
